import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5 text-primary" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.draftsDialog,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.draftsDialog) = $event)),
        "max-width": "900px"
      }, {
        default: _withCtx(() => [
          _createCommentVNode(" <template v-slot:activator=\"{ on, attrs }\">\n              <v-btn color=\"primary\" theme=\"dark\" v-bind=\"attrs\" v-on=\"on\">Open Dialog</v-btn>\n            </template>"),
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__("Select Sales Orders")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "mb-4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            color: "primary",
                            label: _ctx.frappe._('Order ID'),
                            "bg-color": "white",
                            "hide-details": "",
                            modelValue: _ctx.order_name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.order_name) = $event)),
                            density: "compact",
                            clearable: "",
                            class: "mx-4"
                          }, null, 8 /* PROPS */, ["label", "modelValue"]),
                          _createVNode(_component_v_btn, {
                            variant: "text",
                            class: "ml-2",
                            color: "primary",
                            theme: "dark",
                            onClick: $options.search_orders
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.__("Search")), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["onClick"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_row, { "no-gutters": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            class: "pa-1"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_data_table, {
                                headers: _ctx.headers,
                                items: _ctx.dialog_data,
                                "item-key": "name",
                                class: "elevation-1",
                                "show-select": "",
                                modelValue: _ctx.selected,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.selected) = $event)),
                                "return-object": "",
                                "select-strategy": "single"
                              }, {
                                "item.grand_total": _withCtx(({ item }) => [
                                  _createTextVNode(_toDisplayString(_ctx.currencySymbol(item.currency)) + " " + _toDisplayString(_ctx.formatCurrency(item.grand_total)), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["headers", "items", "modelValue"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    theme: "dark",
                    onClick: $options.close_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Close")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  (_ctx.selected.length)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        color: "success",
                        theme: "dark",
                        onClick: $options.submit_dialog
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Select")
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}