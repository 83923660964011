import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5 text-primary" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.closingDialog,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.closingDialog) = $event)),
        "max-width": "900px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__('Closing POS Shift')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            class: "pa-1"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_data_table, {
                                headers: _ctx.headers,
                                items: _ctx.dialog_data.payment_reconciliation,
                                "item-key": "mode_of_payment",
                                class: "elevation-1",
                                "items-per-page": _ctx.itemsPerPage,
                                "hide-default-footer": ""
                              }, {
                                "item.closing_amount": _withCtx(({ item, index }) => [
                                  _createVNode(_component_v_text_field, {
                                    modelValue: item.closing_amount,
                                    "onUpdate:modelValue": $event => ((item.closing_amount) = $event),
                                    type: "text",
                                    label: _ctx.frappe._('Closing Amount'),
                                    prefix: _ctx.currencySymbol(_ctx.pos_profile.currency),
                                    "hide-details": "",
                                    density: "compact",
                                    onFocus: $event => {_ctx.activePopupIndex = index; _ctx.activeKeypadIdx = index; _ctx.selectedPayment = item}
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "prefix", "onFocus"]),
                                  (_ctx.activePopupIndex === index)
                                    ? (_openBlock(), _createBlock(_component_v_menu, {
                                        key: 0,
                                        modelValue: _ctx.dummyMenuModel,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.dummyMenuModel) = $event)),
                                        activator: "parent",
                                        "close-on-content-click": false,
                                        "onClick:outside": _cache[5] || (_cache[5] = $event => (_ctx.activePopupIndex = null)),
                                        "offset-y": ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, {
                                            class: "pa-2 popup-numbers",
                                            style: {"min-width":"300px","max-width":"50%"},
                                            onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, { dense: "" }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (n) => {
                                                    return _createVNode(_component_v_col, {
                                                      cols: "4",
                                                      key: n
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_btn, {
                                                          block: "",
                                                          onClick: $event => ($options.appendNumber(n))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(n), 1 /* TEXT */)
                                                          ]),
                                                          _: 2 /* DYNAMIC */
                                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                                      ]),
                                                      _: 2 /* DYNAMIC */
                                                    }, 1024 /* DYNAMIC_SLOTS */)
                                                  }), 64 /* STABLE_FRAGMENT */)),
                                                  _createVNode(_component_v_col, { cols: "4" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_btn, {
                                                        block: "",
                                                        onClick: _cache[0] || (_cache[0] = $event => ($options.clearAmount()))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode("C")
                                                        ]),
                                                        _: 1 /* STABLE */
                                                      })
                                                    ]),
                                                    _: 1 /* STABLE */
                                                  }),
                                                  _createVNode(_component_v_col, { cols: "4" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_btn, {
                                                        block: "",
                                                        onClick: _cache[1] || (_cache[1] = $event => ($options.appendNumber(0)))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode("0")
                                                        ]),
                                                        _: 1 /* STABLE */
                                                      })
                                                    ]),
                                                    _: 1 /* STABLE */
                                                  }),
                                                  _createVNode(_component_v_col, { cols: "4" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_btn, {
                                                        block: "",
                                                        color: "success",
                                                        onClick: _cache[2] || (_cache[2] = $event => (_ctx.activePopupIndex = null))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.__("OK")), 1 /* TEXT */)
                                                        ]),
                                                        _: 1 /* STABLE */
                                                      })
                                                    ]),
                                                    _: 1 /* STABLE */
                                                  })
                                                ]),
                                                _: 1 /* STABLE */
                                              })
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }, 8 /* PROPS */, ["modelValue"]))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                "item.difference": _withCtx(({ item }) => [
                                  _createTextVNode(_toDisplayString(_ctx.currencySymbol(_ctx.pos_profile.currency)) + " " + _toDisplayString((item.difference = _ctx.formatCurrency(
                        item.expected_amount - item.closing_amount
                      ))), 1 /* TEXT */)
                                ]),
                                "item.opening_amount": _withCtx(({ item }) => [
                                  _createTextVNode(_toDisplayString(_ctx.currencySymbol(_ctx.pos_profile.currency)) + " " + _toDisplayString(_ctx.formatCurrency(item.opening_amount)), 1 /* TEXT */)
                                ]),
                                "item.expected_amount": _withCtx(({ item }) => [
                                  _createTextVNode(_toDisplayString(_ctx.currencySymbol(_ctx.pos_profile.currency)) + " " + _toDisplayString(_ctx.formatCurrency(item.expected_amount)), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["headers", "items", "items-per-page"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    theme: "dark",
                    onClick: $options.close_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__('Close')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    theme: "dark",
                    onClick: $options.submit_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__('Submit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}