import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8763b898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-weight-light" }
const _hoisted_2 = { right: "" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = ["textContent"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")
  const _component_v_img = _resolveComponent("v-img")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_list = _resolveComponent("v-list")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_app_bar = _resolveComponent("v-app-bar")
  const _component_v_avatar = _resolveComponent("v-avatar")
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")
  const _component_v_snackbar = _resolveComponent("v-snackbar")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_v_app_bar, {
      height: "40",
      class: "elevation-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($data.drawer = !$data.drawer), ["stop"])),
          class: "text-grey"
        }),
        _createVNode(_component_v_img, {
          src: "/assets/posawesome/js/posapp/components/pos/pos.png",
          alt: "POS Awesome",
          "max-width": "32",
          class: "mr-2",
          color: "primary"
        }),
        _createVNode(_component_v_toolbar_title, {
          onClick: $options.go_desk,
          style: {"cursor":"pointer"},
          class: "text-uppercase text-primary"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__('ERP System')), 1 /* TEXT */),
            _createCommentVNode(" Code Modified by Salah ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          style: {"cursor":"unset"},
          variant: "text",
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString($data.pos_profile.name), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_menu, { target: "[y]" }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps({
                color: "primary",
                theme: "dark",
                variant: "text"
              }, props), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.__('Menu')), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */),
              _createCommentVNode(" Code Modified by Salah ")
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                class: "mx-auto",
                "max-width": "300",
                tile: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list, {
                    density: "compact",
                    modelValue: $data.menu_item,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.menu_item) = $event)),
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      (!$data.pos_profile.posa_hide_closing_shift && $data.item == 0)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 0,
                            onClick: $options.close_shift_dialog
                          }, {
                            prepend: _withCtx(() => [
                              _createVNode(_component_v_icon, { icon: "mdi-content-save-move-outline" })
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.__('Close Shift')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["onClick"]))
                        : _createCommentVNode("v-if", true),
                      (
                $data.pos_profile.posa_allow_print_last_invoice &&
                this.last_invoice
              )
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 1,
                            onClick: $options.print_last_invoice
                          }, {
                            prepend: _withCtx(() => [
                              _createVNode(_component_v_icon, { icon: "mdi-printer" })
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.__('Print Last Invoice')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["onClick"]))
                        : _createCommentVNode("v-if", true),
                      _createVNode(_component_v_divider, { class: "my-0" }),
                      _createVNode(_component_v_list_item, { onClick: $options.logOut }, {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { icon: "mdi-logout" })
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.__('Logout')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"]),
                      _createVNode(_component_v_list_item, { onClick: $options.go_about }, {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { icon: "mdi-information-outline" })
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.__('Desktop')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: $data.drawer,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.drawer) = $event)),
      "mini-variant": $data.mini,
      "onUpdate:miniVariant": _cache[5] || (_cache[5] = $event => (($data.mini) = $event)),
      class: "bg-primary margen-top",
      width: "170"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, { theme: "dark" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, { class: "px-2" }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_avatar, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, { src: $data.company_img }, null, 8 /* PROPS */, ["src"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.company), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_btn, {
                  icon: "",
                  onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($data.mini = !$data.mini), ["stop"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { icon: "mdi-chevron-left" })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" <MyPopup/> "),
            _createVNode(_component_v_list, {
              modelValue: $data.item,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.item) = $event)),
              color: "white"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: item.text,
                    onClick: $event => ($options.changePage(item.text))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: item.icon
                      }, null, 8 /* PROPS */, ["icon"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            textContent: _toDisplayString(item.text)
                          }, null, 8 /* PROPS */, _hoisted_4)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "mini-variant"]),
    _createVNode(_component_v_snackbar, {
      modelValue: $data.snack,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.snack) = $event)),
      timeout: 5000,
      color: $data.snackColor,
      location: "top right"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($data.snackText), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "color"]),
    _createVNode(_component_v_dialog, {
      modelValue: $data.freeze,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.freeze) = $event)),
      persistent: "",
      "max-width": "290"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-h5" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($data.freezeTitle), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($data.freezeMsg), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}