import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mb-8" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_UpdateCustomer = _resolveComponent("UpdateCustomer")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_autocomplete, {
      density: "compact",
      clearable: "",
      "auto-select-first": "",
      variant: "outlined",
      color: "primary",
      label: _ctx.frappe._('Customer'),
      modelValue: _ctx.customer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.customer) = $event)),
      items: _ctx.customers,
      "item-title": "customer_name",
      "item-value": "name",
      "bg-color": "white",
      "no-data-text": _ctx.__('Customers not found'),
      "hide-details": "",
      customFilter: $options.customFilter,
      disabled: _ctx.readonly,
      "append-icon": "mdi-plus",
      "onClick:append": $options.new_customer,
      "prepend-inner-icon": "mdi-account-edit",
      "onClick:prependInner": $options.edit_customer
    }, {
      item: _withCtx(({ props, item }) => [
        _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
          default: _withCtx(() => [
            (item.raw.customer_name != item.raw.name)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: `ID: ${item.raw.name}`
                    }, null, 8 /* PROPS */, _hoisted_1)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (item.raw.tax_id)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: `TAX ID: ${item.raw.tax_id}`
                    }, null, 8 /* PROPS */, _hoisted_2)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (item.raw.email_id)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 2 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: `Email: ${item.raw.email_id}`
                    }, null, 8 /* PROPS */, _hoisted_3)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (item.raw.mobile_no)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 3 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: `Mobile No: ${item.raw.mobile_no}`
                    }, null, 8 /* PROPS */, _hoisted_4)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (item.raw.primary_address)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: `Primary Address: ${item.raw.primary_address}`
                    }, null, 8 /* PROPS */, _hoisted_5)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text", "customFilter", "disabled", "onClick:append", "onClick:prependInner"]),
    _createTextVNode(" " + _toDisplayString(console.log("my ccustomers", _ctx.customers)) + " ", 1 /* TEXT */),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_UpdateCustomer)
    ])
  ]))
}