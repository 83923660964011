import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = {
  class: "overflow-y-auto px-2 pt-2",
  style: {"max-height":"75vh"}
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { key: 5 }
const _hoisted_12 = { class: "pa-2 py-3" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "text-h5 text-primary" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_date_picker = _resolveComponent("v-date-picker")
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_v_textarea = _resolveComponent("v-textarea")
  const _component_v_switch = _resolveComponent("v-switch")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      class: "selection mx-auto bg-grey-lighten-5 pa-1",
      style: {"max-height":"76vh","height":"76vh"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_linear, {
          active: _ctx.loading,
          indeterminate: _ctx.loading,
          absolute: "",
          location: _ctx.top,
          color: "info"
        }, null, 8 /* PROPS */, ["active", "indeterminate", "location"]),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.invoice_doc)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 0,
                class: "px-1 py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "7" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._('Paid Amount'),
                        "bg-color": "white",
                        "hide-details": "",
                        "model-value": _ctx.formatCurrency($options.total_payments),
                        readonly: "",
                        prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency),
                        density: "compact"
                      }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "5" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._($options.diff_lable),
                        "bg-color": "white",
                        "hide-details": "",
                        "model-value": _ctx.formatCurrency($options.diff_payment),
                        readonly: "",
                        prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency),
                        density: "compact"
                      }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  ($options.diff_payment < 0 && !_ctx.invoice_doc.is_return)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "7"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: "outlined",
                            color: "primary",
                            label: _ctx.frappe._('Paid Change'),
                            "bg-color": "white",
                            modelValue: _ctx.paid_change,
                            "onUpdate:modelValue": [
                              _cache[0] || (_cache[0] = $event => ((_ctx.paid_change) = $event)),
                              _cache[1] || (_cache[1] = $event => ($options.set_paid_change()))
                            ],
                            prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency),
                            rules: _ctx.paid_change_rules,
                            density: "compact",
                            readonly: "",
                            type: "number"
                          }, null, 8 /* PROPS */, ["label", "modelValue", "prefix", "rules"])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  ($options.diff_payment < 0 && !_ctx.invoice_doc.is_return)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 1,
                        cols: "5"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: "outlined",
                            color: "primary",
                            label: _ctx.frappe._('Credit Change'),
                            "bg-color": "white",
                            "hide-details": "",
                            "model-value": _ctx.formatCurrency($options.credit_change),
                            readonly: "",
                            prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency),
                            density: "compact"
                          }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_v_divider),
          (_ctx.is_cashback)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoice_doc.payments, (payment) => {
                  return (_openBlock(), _createBlock(_component_v_row, {
                    class: "pyments px-1 py-0",
                    key: payment.name
                  }, {
                    default: _withCtx(() => [
                      (!$options.is_mpesa_c2b_payment(payment))
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            cols: "6"
                          }, {
                            default: _withCtx(() => [
                              _createCommentVNode(" Start New Code by Salah "),
                              _createVNode(_component_v_text_field, {
                                modelValue: payment.amount,
                                "onUpdate:modelValue": $event => ((payment.amount) = $event),
                                type: "text",
                                density: "compact",
                                variant: "outlined",
                                color: "primary",
                                label: _ctx.frappe._(payment.mode_of_payment),
                                "bg-color": "white",
                                "hide-details": "",
                                rules: [_ctx.isNumber],
                                prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency),
                                onFocus: $event => {_ctx.popupVisible = true; _ctx.activeKeypadIdx = payment.idx; _ctx.selectedPayment = payment},
                                readonly: _ctx.invoice_doc.is_return
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "rules", "prefix", "onFocus", "readonly"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (_ctx.activeKeypadIdx === payment.idx)
                        ? (_openBlock(), _createBlock(_component_v_menu, {
                            key: 1,
                            modelValue: _ctx.popupVisible,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.popupVisible) = $event)),
                            "close-on-content-click": false,
                            "onClick:outside": _cache[7] || (_cache[7] = $event => (_ctx.popupVisible = false)),
                            activator: "parent",
                            "offset-y": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, {
                                class: "pa-2 popup-numbers",
                                style: {"min-width":"300px","max-width":"50%"},
                                onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, { dense: "" }, {
                                    default: _withCtx(() => [
                                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (n) => {
                                        return _createVNode(_component_v_col, {
                                          cols: "4",
                                          key: n
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_btn, {
                                              block: "",
                                              onClick: $event => ($options.appendNumber(n))
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(n), 1 /* TEXT */)
                                              ]),
                                              _: 2 /* DYNAMIC */
                                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1024 /* DYNAMIC_SLOTS */)
                                      }), 64 /* STABLE_FRAGMENT */)),
                                      _createVNode(_component_v_col, { cols: "4" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            block: "",
                                            onClick: _cache[2] || (_cache[2] = $event => ($options.clearAmount()))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("C")
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_v_col, { cols: "4" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            block: "",
                                            onClick: _cache[3] || (_cache[3] = $event => ($options.appendNumber(0)))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("0")
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_v_col, { cols: "4" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            block: "",
                                            color: "success",
                                            onClick: _cache[4] || (_cache[4] = $event => (_ctx.popupVisible = false))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.__("OK")), 1 /* TEXT */)
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["modelValue"]))
                        : _createCommentVNode("v-if", true),
                      _createCommentVNode(" End New Code by Salah "),
                      (!$options.is_mpesa_c2b_payment(payment))
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 2,
                            cols: 6
              ? (payment.type != 'Phone' ||
                payment.amount == 0 ||
                !$options.request_payment_field) &&
              !$options.is_mpesa_c2b_payment(payment)
              : 3
              
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                block: "",
                                class: "",
                                color: "primary",
                                theme: "dark",
                                onClick: $event => ($options.set_full_amount(payment.idx))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(payment.mode_of_payment), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["cols"]))
                        : _createCommentVNode("v-if", true),
                      ($options.is_mpesa_c2b_payment(payment))
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 3,
                            cols: 12,
                            class: "pl-3"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                block: "",
                                class: "",
                                color: "success",
                                theme: "dark",
                                onClick: $event => ($options.mpesa_c2b_dialg(payment))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.__(`Get Payments ${payment.mode_of_payment}`)), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (
              payment.type == 'Phone' &&
              payment.amount > 0 &&
              $options.request_payment_field
            )
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 4,
                            cols: 3,
                            class: "pl-1"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                block: "",
                                class: "",
                                color: "success",
                                theme: "dark",
                                disabled: payment.amount == 0,
                                onClick: $event => (
                (_ctx.phone_dialog = true),
                (payment.amount = _ctx.flt(payment.amount, 0))
                )
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.__("Request")), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true),
          (
          _ctx.invoice_doc &&
          $options.available_pioints_amount > 0 &&
          !_ctx.invoice_doc.is_return
        )
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 2,
                class: "pyments px-1 py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "7" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._('Redeem Loyalty Points'),
                        "bg-color": "white",
                        "hide-details": "",
                        modelValue: _ctx.loyalty_amount,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.loyalty_amount) = $event)),
                        type: "number",
                        prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                      }, null, 8 /* PROPS */, ["label", "modelValue", "prefix"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "5" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._('You can redeem upto'),
                        "bg-color": "white",
                        "hide-details": "",
                        "model-value": _ctx.formatFloat($options.available_pioints_amount),
                        prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency),
                        disabled: ""
                      }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (
          _ctx.invoice_doc &&
          $options.available_customer_credit > 0 &&
          !_ctx.invoice_doc.is_return &&
          _ctx.redeem_customer_credit
        )
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 3,
                class: "pyments px-1 py-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "7" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        variant: "outlined",
                        disabled: "",
                        color: "primary",
                        label: _ctx.frappe._('Redeemed Customer Credit'),
                        "bg-color": "white",
                        "hide-details": "",
                        modelValue: $options.redeemed_customer_credit,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($options.redeemed_customer_credit) = $event)),
                        type: "number",
                        prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                      }, null, 8 /* PROPS */, ["label", "modelValue", "prefix"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "5" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._('You can redeem credit upto'),
                        "bg-color": "white",
                        "hide-details": "",
                        "model-value": _ctx.formatCurrency($options.available_customer_credit),
                        prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency),
                        disabled: ""
                      }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, { class: "px-1 py-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    density: "compact",
                    variant: "outlined",
                    color: "primary",
                    label: _ctx.frappe._('Net Total'),
                    "bg-color": "white",
                    "hide-details": "",
                    "model-value": _ctx.formatCurrency(_ctx.invoice_doc.net_total),
                    disabled: "",
                    prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                  }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    density: "compact",
                    variant: "outlined",
                    color: "primary",
                    label: _ctx.frappe._('Tax and Charges'),
                    "bg-color": "white",
                    "hide-details": "",
                    "model-value": _ctx.formatCurrency(_ctx.invoice_doc.total_taxes_and_charges),
                    disabled: "",
                    prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                  }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    density: "compact",
                    variant: "outlined",
                    color: "primary",
                    label: _ctx.frappe._('Total Amount'),
                    "bg-color": "white",
                    "hide-details": "",
                    "model-value": _ctx.formatCurrency(_ctx.invoice_doc.total),
                    disabled: "",
                    prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                  }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    density: "compact",
                    variant: "outlined",
                    color: "primary",
                    label: _ctx.frappe._('Discount Amount'),
                    "bg-color": "white",
                    "hide-details": "",
                    "model-value": _ctx.formatCurrency(_ctx.invoice_doc.discount_amount),
                    disabled: "",
                    prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                  }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    density: "compact",
                    variant: "outlined",
                    color: "primary",
                    label: _ctx.frappe._('Grand Total'),
                    "bg-color": "white",
                    "hide-details": "",
                    "model-value": _ctx.formatCurrency(_ctx.invoice_doc.grand_total),
                    disabled: "",
                    prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                  }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    density: "compact",
                    variant: "outlined",
                    color: "primary",
                    "bg-color": "white",
                    items: [
				  { label: _ctx.frappe._('Dine-in'), value: 'Dine-in' },
				  { label: _ctx.frappe._('Takeaway'), value: 'Takeaway' }
				],
                    modelValue: _ctx.custom_order_type,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.custom_order_type) = $event)),
                    label: _ctx.frappe._('Order Type'),
                    "item-title": "label",
                    "item-value": "value",
                    "hide-details": "",
                    "prepend-inner-icon": "mdi-storefront"
                  }, null, 8 /* PROPS */, ["items", "modelValue", "label"])
                ]),
                _: 1 /* STABLE */
              }),
              (_ctx.invoice_doc.rounded_total)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._('Rounded Total'),
                        "bg-color": "white",
                        "hide-details": "",
                        "model-value": _ctx.formatCurrency(_ctx.invoice_doc.rounded_total),
                        disabled: "",
                        prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                      }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (_ctx.pos_profile.posa_allow_sales_order && _ctx.invoiceType == 'Order')
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_menu, {
                        ref: "order_delivery_date",
                        modelValue: _ctx.order_delivery_date,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.order_delivery_date) = $event)),
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        density: "default"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.invoice_doc.posa_delivery_date,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.invoice_doc.posa_delivery_date) = $event)),
                            label: _ctx.frappe._('Delivery Date'),
                            readonly: "",
                            variant: "outlined",
                            density: "compact",
                            "bg-color": "white",
                            clearable: "",
                            color: "primary",
                            "hide-details": ""
                          }, props), null, 16 /* FULL_PROPS */, ["modelValue", "label"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_date_picker, {
                            "v-model": new Date(_ctx.invoice_doc.posa_delivery_date),
                            "no-title": "",
                            scrollable: "",
                            color: "primary",
                            min: _ctx.frappe.datetime.now_date(),
                            onInput: _cache[12] || (_cache[12] = $event => (_ctx.order_delivery_date = false))
                          }, null, 8 /* PROPS */, ["v-model", "min"])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (_ctx.invoice_doc.posa_delivery_date)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_autocomplete, {
                        density: "compact",
                        clearable: "",
                        "auto-select-first": "",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._('Address'),
                        modelValue: _ctx.invoice_doc.shipping_address_name,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.invoice_doc.shipping_address_name) = $event)),
                        items: _ctx.addresses,
                        "item-title": "address_title",
                        "item-value": "name",
                        "bg-color": "white",
                        "no-data-text": "Address not found",
                        "hide-details": "",
                        customFilter: $options.addressFilter,
                        "append-icon": "mdi-plus",
                        "onClick:append": $options.new_address
                      }, {
                        item: _withCtx(({ props, item }) => [
                          _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, { class: "text-primary text-subtitle-1" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: item.raw.address_title
                                  }, null, 8 /* PROPS */, _hoisted_3)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */),
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: item.raw.address_line1
                                  }, null, 8 /* PROPS */, _hoisted_4)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */),
                              (item.raw.custoaddress_line2mer_name)
                                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: item.raw.address_line2
                                      }, null, 8 /* PROPS */, _hoisted_5)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                : _createCommentVNode("v-if", true),
                              (item.raw.city)
                                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 1 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: item.raw.city
                                      }, null, 8 /* PROPS */, _hoisted_6)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                : _createCommentVNode("v-if", true),
                              (item.raw.state)
                                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 2 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: item.raw.state
                                      }, null, 8 /* PROPS */, _hoisted_7)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                : _createCommentVNode("v-if", true),
                              (item.raw.country)
                                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 3 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: item.raw.mobile_no
                                      }, null, 8 /* PROPS */, _hoisted_8)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                : _createCommentVNode("v-if", true),
                              (item.raw.address_type)
                                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 4 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: item.raw.address_type
                                      }, null, 8 /* PROPS */, _hoisted_9)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["label", "modelValue", "items", "customFilter", "onClick:append"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (_ctx.pos_profile.posa_display_additional_notes)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 3,
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_textarea, {
                        class: "pa-0",
                        variant: "outlined",
                        density: "compact",
                        "bg-color": "white",
                        clearable: "",
                        color: "primary",
                        "auto-grow": "",
                        rows: "2",
                        label: _ctx.frappe._('Additional Notes'),
                        modelValue: _ctx.invoice_doc.posa_notes,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.invoice_doc.posa_notes) = $event)),
                        "model-value": _ctx.invoice_doc.posa_notes
                      }, null, 8 /* PROPS */, ["label", "modelValue", "model-value"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.pos_profile.posa_allow_customer_purchase_order)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_v_divider),
                _createVNode(_component_v_row, {
                  class: "px-1 py-0",
                  justify: "center",
                  align: "start"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.invoice_doc.po_no,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.invoice_doc.po_no) = $event)),
                          label: _ctx.frappe._('Purchase Order'),
                          variant: "outlined",
                          density: "compact",
                          "bg-color": "white",
                          clearable: "",
                          color: "primary",
                          "hide-details": ""
                        }, null, 8 /* PROPS */, ["modelValue", "label"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_menu, {
                          ref: "po_date_menu",
                          modelValue: _ctx.po_date_menu,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.po_date_menu) = $event)),
                          "close-on-content-click": false,
                          transition: "scale-transition"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_text_field, _mergeProps({
                              modelValue: _ctx.invoice_doc.po_date,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.invoice_doc.po_date) = $event)),
                              label: _ctx.frappe._('Purchase Order Date'),
                              readonly: "",
                              variant: "outlined",
                              density: "compact",
                              "hide-details": ""
                            }, props, { color: "primary" }), null, 16 /* FULL_PROPS */, ["modelValue", "label"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_date_picker, {
                              modelValue: _ctx.invoice_doc.po_date,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.invoice_doc.po_date) = $event)),
                              "no-title": "",
                              scrollable: "",
                              color: "primary",
                              onInput: _cache[19] || (_cache[19] = $event => (_ctx.po_date_menu = false))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, {
            class: "px-1 py-0",
            align: "start",
            "no-gutters": ""
          }, {
            default: _withCtx(() => [
              (
            _ctx.pos_profile.posa_allow_write_off_change &&
            $options.diff_payment > 0 &&
            !_ctx.invoice_doc.is_return
          )
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        class: "my-0 py-0",
                        modelValue: _ctx.is_write_off_change,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.is_write_off_change) = $event)),
                        flat: "",
                        label: _ctx.frappe._('Write Off Difference Amount')
                      }, null, 8 /* PROPS */, ["modelValue", "label"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (_ctx.pos_profile.posa_allow_credit_sale && !_ctx.invoice_doc.is_return)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        modelValue: _ctx.is_credit_sale,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.is_credit_sale) = $event)),
                        label: _ctx.frappe._('Credit Sale?')
                      }, null, 8 /* PROPS */, ["modelValue", "label"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (_ctx.invoice_doc.is_return && _ctx.pos_profile.use_cashback)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        modelValue: _ctx.is_cashback,
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.is_cashback) = $event)),
                        flat: "",
                        label: _ctx.frappe._('Cashback?'),
                        class: "my-0 py-0"
                      }, null, 8 /* PROPS */, ["modelValue", "label"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (_ctx.is_credit_sale)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 3,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_menu, {
                        ref: "date_menu",
                        modelValue: _ctx.date_menu,
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => ((_ctx.date_menu) = $event)),
                        "close-on-content-click": false,
                        transition: "scale-transition"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.invoice_doc.due_date,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.invoice_doc.due_date) = $event)),
                            label: _ctx.frappe._('Due Date'),
                            readonly: "",
                            variant: "outlined",
                            density: "compact",
                            "hide-details": ""
                          }, props, { color: "primary" }), null, 16 /* FULL_PROPS */, ["modelValue", "label"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_date_picker, {
                            modelValue: _ctx.credit_sales_due_date,
                            "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.credit_sales_due_date) = $event)),
                            "no-title": "",
                            scrollable: "",
                            color: "primary",
                            min: _ctx.frappe.datetime.now_date(),
                            onInput: _cache[26] || (_cache[26] = $event => (_ctx.date_menu = false))
                          }, null, 8 /* PROPS */, ["modelValue", "min"])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (!_ctx.invoice_doc.is_return && _ctx.pos_profile.use_customer_credit)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 4,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        modelValue: _ctx.redeem_customer_credit,
                        "onUpdate:modelValue": [
                          _cache[28] || (_cache[28] = $event => ((_ctx.redeem_customer_credit) = $event)),
                          _cache[29] || (_cache[29] = $event => ($options.get_available_credit($event)))
                        ],
                        flat: "",
                        label: _ctx.frappe._('Use Customer Credit'),
                        class: "my-0 py-0"
                      }, null, 8 /* PROPS */, ["modelValue", "label"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          (
          _ctx.invoice_doc &&
          $options.available_customer_credit > 0 &&
          !_ctx.invoice_doc.is_return &&
          _ctx.redeem_customer_credit
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer_credit_dict, (row, idx) => {
                  return (_openBlock(), _createBlock(_component_v_row, { key: idx }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_12, _toDisplayString(row.credit_origin), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            density: "compact",
                            variant: "outlined",
                            color: "primary",
                            label: _ctx.frappe._('Available Credit'),
                            "bg-color": "white",
                            "hide-details": "",
                            "model-value": _ctx.formatCurrency(row.total_credit),
                            disabled: "",
                            prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                          }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            density: "compact",
                            variant: "outlined",
                            color: "primary",
                            label: _ctx.frappe._('Redeem Credit'),
                            "bg-color": "white",
                            "hide-details": "",
                            type: "number",
                            modelValue: row.credit_to_redeem,
                            "onUpdate:modelValue": $event => ((row.credit_to_redeem) = $event),
                            prefix: _ctx.currencySymbol(_ctx.invoice_doc.currency)
                          }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue", "prefix"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, {
            class: "pb-0 mb-2",
            align: "start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_autocomplete, {
                    density: "compact",
                    clearable: "",
                    variant: "outlined",
                    color: "primary",
                    label: _ctx.frappe._('Sales Person'),
                    modelValue: _ctx.sales_person,
                    "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => ((_ctx.sales_person) = $event)),
                    items: _ctx.sales_persons,
                    "item-title": "sales_person_name",
                    "item-value": "name",
                    "bg-color": "white",
                    "no-data-text": _ctx.__('Sales Person not found'),
                    "hide-details": "",
                    customFilter: $options.salesPersonFilter,
                    disabled: _ctx.readonly
                  }, {
                    item: _withCtx(({ props, item }) => [
                      _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "text-primary text-subtitle-1" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                innerHTML: item.raw.sales_person_name
                              }, null, 8 /* PROPS */, _hoisted_13)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */),
                          (item.raw.sales_person_name != item.raw.name)
                            ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: `ID: ${item.raw.name}`
                                  }, null, 8 /* PROPS */, _hoisted_14)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text", "customFilter", "disabled"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_card, {
      flat: "",
      class: "cards mb-0 mt-3 py-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "start",
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  block: "",
                  size: "large",
                  color: "primary",
                  theme: "dark",
                  onClick: $options.submit,
                  disabled: $options.vaildatPayment
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Submit")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick", "disabled"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "6",
              class: "pl-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  block: "",
                  size: "large",
                  color: "success",
                  theme: "dark",
                  onClick: _cache[31] || (_cache[31] = $event => ($options.submit(undefined, false, true))),
                  disabled: $options.vaildatPayment
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Submit & Print")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  block: "",
                  class: "mt-2 pa-1",
                  size: "large",
                  color: "error",
                  theme: "dark",
                  onClick: $options.back_to_invoice
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Cancel Payment")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", null, [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.phone_dialog,
        "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => ((_ctx.phone_dialog) = $event)),
        "max-width": "400px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.__("Confirm Mobile Number")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.frappe._('Mobile Number'),
                        "bg-color": "white",
                        "hide-details": "",
                        modelValue: _ctx.invoice_doc.contact_mobile,
                        "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => ((_ctx.invoice_doc.contact_mobile) = $event)),
                        type: "number"
                      }, null, 8 /* PROPS */, ["label", "modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    theme: "dark",
                    onClick: _cache[33] || (_cache[33] = $event => (_ctx.phone_dialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__("Close")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    theme: "dark",
                    onClick: $options.request_payment
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__("Request")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}