import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-h5 text-primary" }
const _hoisted_2 = { class: "text-caption text-primary accent-3" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_chip = _resolveComponent("v-chip")
  const _component_v_chip_group = _resolveComponent("v-chip-group")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_img = _resolveComponent("v-img")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.varaintsDialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.varaintsDialog) = $event)),
        "max-width": "600px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { "min-height": "500px" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__("Select Item")), 1 /* TEXT */),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    theme: "dark",
                    onClick: $options.close_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__("Close")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  (_ctx.parentItem)
                    ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parentItem.attributes, (attr) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: attr.attribute
                            }, [
                              _createVNode(_component_v_chip_group, {
                                modelValue: _ctx.filters[attr.attribute],
                                "onUpdate:modelValue": $event => ((_ctx.filters[attr.attribute]) = $event),
                                "selected-class": "green--text text--accent-4",
                                column: ""
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attr.values, (value) => {
                                    return (_openBlock(), _createBlock(_component_v_chip, {
                                      key: value.abbr,
                                      value: value.attribute_value,
                                      variant: "outlined",
                                      label: "",
                                      onClick: $options.updateFiltredItems
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(value.attribute_value), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onClick"]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_v_divider, { class: "p-0 m-0" })
                            ]))
                          }), 128 /* KEYED_FRAGMENT */)),
                          _createElementVNode("div", null, [
                            _createVNode(_component_v_row, {
                              density: "default",
                              class: "overflow-y-auto",
                              style: {"max-height":"500px"}
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterdItems, (item, idx) => {
                                  return (_openBlock(), _createBlock(_component_v_col, {
                                    key: idx,
                                    xl: "2",
                                    lg: "3",
                                    md: "4",
                                    sm: "4",
                                    cols: "6",
                                    "min-height": "50"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card, {
                                        hover: "hover",
                                        onClick: $event => ($options.add_item(item))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_img, {
                                            src: item.image ||
                      '/assets/posawesome/js/posapp/components/pos/placeholder-image.png'
                      ,
                                            class: "text-white align-end",
                                            gradient: "to bottom, rgba(0,0,0,.2), rgba(0,0,0,.7)",
                                            height: "100px"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_card_text, {
                                                textContent: _toDisplayString(item.item_name),
                                                class: "text-subtitle-2 px-1 pb-2"
                                              }, null, 8 /* PROPS */, ["textContent"])
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["src"]),
                                          _createVNode(_component_v_card_text, { class: "text--primary pa-1" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_2, _toDisplayString(item.rate || 0) + " " + _toDisplayString(item.currency || ''), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]),
                              _: 1 /* STABLE */
                            })
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}